import { RouteRecordRaw } from "vue-router";

import { UserRole } from "../api/models/user";

const routes: RouteRecordRaw[] = [
  { path: "/", redirect: "/login" },
  {
    path: "/accounts",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    meta: { requiresAuth: true, priority: 0 },
    children: [
      {
        name: "Accounts",
        path: "",
        component: () => import("../components/CompanyTable.vue"),
        meta: { requiresAuth: true },
      },
      {
        name: "New account",
        path: "new",
        component: () => import("../views/admin/CompanyForm.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: ":id",
        component: () => import("../components/RouterView.vue"),
        meta: { requiresAuth: true },
        children: [
          {
            name: "Account Details",
            path: "",
            component: () => import("../views/admin/CompanyDetails.vue"),
            meta: { requiresAuth: true },
          },
          {
            name: "Edit Account Info",
            path: "edit",
            component: () => import("../views/admin/CompanyForm.vue"),
            props: {
              formType: "update",
            },
            meta: { requiresAuth: true },
          },
          {
            name: "Contract History",
            path: "contract-history",
            component: () => import("../views/admin/ContractHistory.vue"),
            meta: { requiresAuth: true },
          },
        ],
      },
    ],
  },
  {
    path: "/metrics",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        name: "Metrics",
        path: "",
        component: () => import("../components/analytics/AnalyticsTable.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/kala-users",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    meta: { requiresAuth: true, allowedUserRoles: [UserRole.PLATFORM_ADMINISTRATOR] },
    children: [
      {
        name: "Kala users",
        path: "",
        meta: { requiresAuth: true },
        component: () => import("../components/platformPersonnel/PlatformPersonnelTable.vue"),
      },
      {
        name: "New Kala user",
        path: "new",
        meta: { requiresAuth: true },
        component: () => import("../components/platformPersonnel/PlatformPersonnelForm.vue"),
      },
      {
        name: "Edit Kala user",
        path: ":id",
        meta: { requiresAuth: true },
        component: () => import("../components/platformPersonnel/PlatformPersonnelForm.vue"),
        props: {
          formType: "update",
        },
      },
    ],
  },
  {
    path: "/kala-offices",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        name: "Offices",
        path: "",
        component: () => import("../components/offices/OfficeTable.vue"),
        meta: { requiresAuth: true },
      },
      {
        name: "New office",
        path: "new",
        component: () => import("../components/offices/OfficeForm.vue"),
        meta: { requiresAuth: true },
      },
      {
        name: "Edit office",
        path: ":id",
        component: () => import("../components/offices/OfficeForm.vue"),
        props: { formType: "update" },
        meta: { requiresAuth: true },
      },
    ],
  },
];

export default routes;
